import { StaticImageData } from 'next/image';

export function getCfdCategorySlugs() {
  return ['indices', 'forex', 'commodities', 'shares', 'crypto'];
}

export function getCfdAssetCategoryBySlug(slug: string) {
  return {
    indices: 'indices',
    forex: 'forex',
    commodities: 'commodities',
    shares: 'stocks',
    crypto: 'crypto',
  }[slug];
}

export async function getFees(): Promise<{
  [key: string]: {
    trading_fee: number;
    max_leverage: string;
    assets: number;
    name: string;
  };
}> {
  return Promise.resolve({
    crypto: {
      trading_fee: 0.05,
      max_leverage: '(1:200)',
      assets: 38,
      name: 'crypto',
    },
    forex: {
      trading_fee: 0,
      max_leverage: '(1:1000)',
      assets: 42,
      name: 'forex',
    },
    indices: {
      trading_fee: 0,
      max_leverage: '(1:100)',
      assets: 11,
      name: 'indices',
    },
    commodities: {
      trading_fee: 0,
      max_leverage: '(1:500)',
      assets: 15,
      name: 'commodities',
    },
    shares: {
      trading_fee: 0,
      max_leverage: '(1:5)',
      assets: 0,
      name: 'shares',
    },
  });
}

export function getCfdHubUrl(category?: string): string {
  const prefix = '/cfd-trading/';
  return prefix + (category || 'markets');
}

export function getHomePagePopularAssetNames() {
  return ['GOLD', 'BTC/USD', 'SP500', 'EUR/USD', 'USD/JPY', 'BRENT'];
}

export function getAssetsNamesBySlug(slug: string): string[] {
  const assets = {
    crypto: ['BTC/USD', 'ETH/USD', 'DOGE/USD', 'FTM/USD', 'XRP/USD', 'SOL/USD'],
    forex: ['EUR/USD', 'GBP/USD', 'USD/JPY', 'EUR/JPY', 'USD/CAD', 'CAD/JPY'],
    indices: ['NASDAQ', 'SP500', 'GER30', 'DOWJ', 'EUR50', 'JAPAN'],
    commodities: ['GOLD', 'SILVER', 'NAT.GAS', 'CRUDE', 'BRENT', 'ALUMINIUM'],
    shares: ['ABNB', 'ADBE', 'ADSK', 'AMD', 'AMZN', 'GOOGL'],
  };
  return assets[slug as keyof typeof assets];
}

export function getCryptoFuturesAssetsNames() {
  return ['BTC/USDT', 'ETH/USDT', 'DOGE/USDT', 'FTM/USDT', 'XRP/USDT', 'SOL/USDT'];
}

export function getCurrencyIcon(ticker: string, isFiat: boolean = false): StaticImageData | string {
  const basePath = isFiat ? 'fiat' : 'coins';

  try {
    const icon = require(`@/app/images/icons/${basePath}/${ticker.toLowerCase()}.svg`);
    return icon.default;
  } catch (error) {
    console.error(`Error loading icon for ${ticker}:`, error);
    return '';
  }
}
